import React from "react";

import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { useAuth } from "../context/AuthProvider";

// const END_POINT = "localhost:4000/graphql";
const END_POINT = "yrcapi.herokuapp.com/graphql";

const ApolloProviderWrapper = ({ children }) => {
  const { authState } = useAuth();
  const { token } = authState ?? {};

  const uploadLinkOptions = {
    uri: `https://${END_POINT}`,
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      "keep-alive": "true",
    },
  };

  const uploadLink = createUploadLink(uploadLinkOptions);

  const wsLink = new WebSocketLink({
    uri: `wss://${END_POINT}`,
    
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    uploadLink
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
