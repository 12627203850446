import React, { createContext, useContext, useEffect, useState } from "react";
const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const initialAuthState = {
    token: "",
    loading: true, // დავამატოთ loading მდგომარეობა
  };
  const [authState, setAuthState] = useState(initialAuthState);

  const signIn = (token) => {
    try {
      if (token) {
        localStorage.setItem("@yrcToken", token);
        setAuthState({ token, loading: false });
      }
    } catch (error) {
      console.error("SignIn Error:", error);
    }
  };

  const signOut = () => {
    try {
      localStorage.removeItem("@yrcToken");
      setAuthState({ token: "", loading: false });
    } catch (error) {
      console.log("SignOut Error:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("@yrcToken");
    setAuthState({ token: token || "", loading: false }); // მხოლოდ ერთი განახლება
  }, []);

  return (
    <AuthContext.Provider value={{ authState, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider as default, useAuth };